<template>
  <div class="center-detail">
    <h2 align="center">实时打破数据孤岛，湖仓一体打造金融行业数据管理平台</h2>
    <p class="to-p" align="center">发布时间：2022-01-11</p>
    <div class="p-box">
      <p>
        2021年是我国全面建成小康社会，实现第一个百年奋斗目标之后，向第二个百年奋斗目标进军的开局之年，这一年信息技术应用创新快速发展，数字孪生、大数据、人工智能等将引领国家信息化驶入快轨道。随着国际上“数字地球”概念的提出，地理信息技术作为地球信息、资源、环境的一个缩影，在各个领域的广泛应用，为社会带来巨大经济效益的同时，在空间信息系统中的重要性愈发凸显。
      </p>
      <p>
        2021年是我国全面建成小康社会，实现第一个百年奋斗目标之后，向第二个百年奋斗目标进军的开局之年，这一年信息技术应用创新快速发展，数字孪生、大数据、人工智能等将引领国家信息化驶入快轨道。随着国际上“数字地球”概念的提出，地理信息技术作为地球信息、资源、环境的一个缩影，在各个领域的广泛应用，为社会带来巨大经济效益的同时，在空间信息系统中的重要性愈发凸显。
        2021年是我国全面建成小康社会，实现第一个百年奋斗目标之后，向第二个百年奋斗目标进军的开局之年，这一年信息技术应用创新快速发展，
      </p>
      <p class="img-p">
        <img src="../../assets/image/63.png" />
      </p>
      <p>
        2021年是我国全面建成小康社会，实现第一个百年奋斗目标之后，向第二个百年奋斗目标进军的开局之年，这一年信息技术应用创新快速发展，数字孪生、大数据、人工智能等将引领国家信息化驶入快轨道。随着国际上“数字地球”概念的提出，地理信息技术作为地球信息、资源、环境的一个缩影，在各个领域的广泛应用，为社会带来巨大经济效益的同时，在空间信息系统中的重要性愈发凸显。
        2021年是我国全面建成小康社会，实现第一个百年奋斗目标之后，向第二个百年奋斗目标进军的开局之年，这一年信息技术应用创新快速发展，
      </p>
    </div>

    <div class="box-bottom">
      <p>
        <span>下一篇：</span>实时打破数据孤岛，湖仓一体打造金融行业数据管理平台
      </p>
      <p>
        <span>上一篇：</span
        >实时打破数据孤岛，湖仓一体打造金融行业数据管理平台造金融行业数据管理平台
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.center-detail {
  h2 {
    margin-top: 120px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0e0c1b;
  }
  .to-p {
    margin-top: 30px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  .p-box {
    width: 1440px;
    margin: 0 auto;
    .img-p {
      text-align: center;
    }
  }
  .box-bottom {
    width: 1440px;
    margin: 60px auto;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
    p {
      margin-top: 15px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      span {
        color: #ff6e29;
      }
    }
  }
}
</style>
